.auth-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    margin: 136px 72px 72px;
}

.auth-container > .logo {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: fixed;
    top: 33px;
    left: 50%;
    transform: translateX(-50%);
    gap: 4px;
    z-index: 999;
}

.auth-container > .logo > .for-business {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #139CFF;
}

.auth-container > .auth {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    max-width: 1356px;
}

.auth-container > .auth > .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 360px;

    flex: none;
    order: 1;
    flex-grow: 0;
}

.auth-container > .auth > .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 360px;
}

.auth-container > .auth > .auth-form {

}

.auth-container > .auth > .auth-form {

}

.auth-container > .auth > .auth-form > .switch-button > .end-icon {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

.auth-container > .auth > .auth-form > .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;

    width: 360px;

    background: #FFFFFF;
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.01), 0px 12px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
}

.auth-container > .auth > .auth-form > .card > .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.auth-container > .auth > .auth-form > .card > .header > .label {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

    color: #222222;
}

.auth-container > .auth > .auth-form > .card > .header > .description {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #84878A;
}

.auth-container > .auth > .auth-form > .card > .error {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #FF3B30;
}

.auth-container > .auth > .auth-form > .card > .header > .slider {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;


    background: #EEEEEE;
    border-radius: 70px;
    max-height: 44px;
}

.auth-container > .auth > .auth-form > .card > .header > .slider > .element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;

    border-radius: 120px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;
    cursor: pointer;

    transition: all 0.1s ease-in-out;

    white-space: nowrap;
}

.auth-container > .auth > .auth-form > .card > .header > .slider > .active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;

    background: #FFFFFF;
    border-radius: 120px;

    transition: all 0.1s ease-in-out;
}

.auth-container > .auth > .auth-form > .card > .forgot {
    flex-direction: column;
}


.auth-container > .auth > .auth-form > .card > .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.auth-container > .auth > .auth-form > .card > .content > .label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    color: #C1C1C1;
}

.auth-container > .auth > .auth-form > .card > .authorize {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 3px;

    width: 100%;

    background: #EEEEEE;
    border-radius: 12px;
    box-sizing: border-box;
}

.auth-container > .auth > .auth-form > .card > .authorize > .label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #747474;
}

.auth-container > .auth > .auth-form > .card > .authorize > .email {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #222222;
}

.auth-container > .auth > .auth-form > .card > .inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 100%;
}

.auth-container > .auth > .auth-form > .card > .inputs > .input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #C1C1C1;

    width: 100%;
}

.auth-container > .auth > .auth-form > .card > .inputs > .input > input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #000000;
    width: 100%;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 8px;

    height: 48px;

    border: 1px solid #C1C1C1;
    border-radius: 12px;

    outline: none;
}

.auth-container > .auth > .auth-form > .card > .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;

    background: #139CFF;
    border-radius: 12px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;

    width: 100%;
    box-sizing: border-box;

    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.auth-container > .auth > .auth-form > .card > .button:hover {
    opacity: 0.7;
}

.auth-container > .auth > .auth-form > .extra {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.auth-container > .auth > .auth-form > .extra > .link {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #C1C1C1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.auth-container > .auth > .auth-form > .extra > .link:hover {
    opacity: 0.6;
}

.auth-container > .auth > .auth-form > .extra > .register {
    color: #139CFF;
}

.auth-container > .auth > .auth-form > .extra > .forgot {
    color: #C1C1C1;
}


@media (max-width: 768px) {
    .auth-container > .logo {
        display: none;
    }

    .auth-container > .auth > .auth-form {
        width: initial;
    }

    .auth-container > .auth > .auth-form > .card {
        min-width: 300px;
        box-sizing: border-box;
    }
}