.text-field-component {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    height: auto;

    border: 1px solid #F4F4F9;
    border-radius: 16px;

    transition: var(--animation-fast);
}

.text-field-component:hover {
    border: 1px solid #CFD2D3;
}

.text-field-component.full-width {
    width: 100%;
}

.text-field-component > .hidden-label {

}

.text-field-component input,
.text-field-component textarea {
    width: 100%;
    padding: 25px 16px 7px;

    border: none;
    outline: none;
    transition: var(--animation-fast);

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #262626;

    border-radius: 16px;
    resize: none;
}

.text-field-component textarea {
    min-height: 80px;
}

.text-field-component input:focus,
.text-field-component textarea:focus {
    border-color: #007BFF;
}

.text-field-component label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.2s ease;

    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    color: #84878A;

}

.text-field-component.focused label,
.text-field-component.filled label,
.text-field-component input:not(:placeholder-shown) + label,
.text-field-component textarea:not(:placeholder-shown) + label {
    top: 50%;
    left: 16px;
    transform: translateY(-16px);
    font-size: 12px;
}

.text-field-component.focused {
    border: 1px solid #139CFF;
}
