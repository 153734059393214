.button-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    transition: var(--animation-fast);
    cursor: pointer;

    font-family: 'Golos', sans-serif;
    font-style: normal;

    width: fit-content;
}

.button-component > .icon > svg {
    height: 100%;
    width: 100%;
}

.button-component.full-width {
    width: 100%;
}


/* Types */

.button-component.accent {
    background: #139CFF;

    color: #ffffff;
}

.button-component.accent:hover {
    background: #2391E0;
}

.button-component.accent:active {
    background: #197EC7;
}


.button-component.transparent {
    background: transparent;

    color: #262626;
}

.button-component.transparent:hover {
    background: #F4F4F9;
}

.button-component.transparent:active {
    background: #ECEFF1;
}


.button-component.outlined {
    border: 1px solid #CFD2D3;

    color: #262626;
}

.button-component.outlined:hover {
    border: 1px solid #84878A;
}

.button-component.outlined:active {
    border: 1px solid #139CFF;
}


.button-component.secondary {
    background: #F4F4F9;
    backdrop-filter: blur(40px);

    color: #262626;
}

.button-component.secondary:hover {
    background: #ECEFF1;
}

.button-component.secondary:active {
    background: #CFD2D3;
}


.button-component.primary {
    background: #262626;

    color: #FFFFFF;
}

.button-component.primary:hover {
    background: #000000;
}

.button-component.primary:active {
    background: #000000;
}


.button-component.text {
    background: transparent;

    color: #262626;
}

.button-component.text:hover {
    background: #000000;
}

.button-component.text:active {
    background: #000000;
}


/* Sizes */

.button-component.x-small {
    padding: 4px 12px;
    border-radius: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    height: 32px;
}

.button-component.x-small > .icon {
    height: 16px;
    width: 16px;
}

.button-component.small {
    padding: 4px 12px;
    border-radius: 12px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    height: 40px;
}

.button-component.small > .icon {
    height: 24px;
    width: 24px;
}

.button-component.medium {
    padding: 4px 16px;
    border-radius: 12px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    height: 40px;
}

.button-component.medium > .icon {
    height: 24px;
    width: 24px;
}

.button-component.large {
    padding: 4px 20px;
    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    height: 48px;
}

.button-component.large > .icon {
    height: 24px;
    width: 24px;
}

.button-component.extra-large {
    padding: 4px 24px;
    border-radius: 12px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    height: 56px;
}

.button-component.extra-large > .icon {
    height: 24px;
    width: 24px;
}


/* Other params */

.button-component.disabled {
    background: #F4F4F9 !important;
    backdrop-filter: blur(40px) !important;

    color: #84878A;

    cursor: default;
}

.button-component.icon {
    justify-content: center;
    padding: 0;
}

.button-component.icon.x-small {
    border-radius: 6px;

    height: 32px;
    width: 32px;
    min-width: 32px;
}

.button-component.icon.small {
    border-radius: 8px;

    height: 40px;
    width: 40px;
    min-width: 40px;
}

.button-component.icon.medium {
    border-radius: 8px;

    height: 40px;
    width: 40px;
    min-width: 40px;
}

.button-component.icon.large {
    border-radius: 8px;

    height: 48px;
    width: 48px;
    min-width: 48px;
}

.button-component.icon.extra-large {
    border-radius: 12px;

    height: 56px;
    width: 56px;
    min-width: 56px;
}

.button-component.icon > .entry {
    display: none;
}


/* Elements */

.button-component > .entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 4px;
}

.button-component > .entry > .text {

}

.button-component > .entry > .hint {

}